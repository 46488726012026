/* IMAGES */

.bg-float {
    position: absolute;
    right: -3px;
    top: 35px;
    width: 35%;
    z-index: 100;
}

.header-logo-width{
  min-width: 250px!important;
 
}

.width-bg-logo{
  max-width: 40vw!important;
}


.logo-position{
 transform: skewX(26deg);
 max-width: 500px;
 padding-left: 35px;
 margin-top: 2vh;

}

.header-logo-outline{
  outline: 20px solid #16609B;
  border-radius: 0 0 0.2rem;
}

.logo-bg-transform {
  height: 70px;
  max-width: 25vw;
  transform: skew(336deg) translateX(-25px);
  background: #16609B;
  border-bottom-right-radius: 20px;
  outline: 28px solid #16609B;
}

.logo-bg-transform-mobile{
  height: 70px;
  transform: skew(336deg) translateX(-25px);
  background: #16609B;
  border-bottom-right-radius: 20px;
  outline: 28px solid #16609B;
} 

.iso-logo-card{
  width: 100px;
}

.activity-card-image{
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.footer-image{
  width: 150px!important;
}

/* WRAPPERS */

.fullscreen-wrapper{
  min-height: 78vh;
}

.content-wrapper{
  margin-top: 70px!important;
  margin-left: 0px!important;
  margin-right: 0px!important;
  margin-bottom: 35px!important;
  /*padding: 0px!important; */
}

.w90 {
  width: 90%;
}
/* FONTS */
.nunito-sans{
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
  line-height:100%;
}

.noto-sans {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}


.crimson {
  font-family: "Crimson Text", serif;
  font-weight: 500;
  font-style: normal;
}

.pt-sans {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* FONTS SIZES*/

.super-titles-size{
  font-size: 48px!important;
}

.super-subtitles-size{
  font-size: 26px!important;
}

.titles-size{
font-size: 30px!important;
}

.subtitles-size{
  font-size: 25px!important;
}

.texts-size{
  font-size: 18px!important;
}

/* COLORS */

.bg-primario{
  background-color: #16609B!important;
}

.bg-secundario{
  background-color: #2DC2E5!important;
}

.bg-green{
 background-color: #00B480;
}

.bg-red{
  background-color: #C12153;
}

.outline-primario{
  color: #16609B!important;
  border-color: #16609B!important;
}

.outline-secundario{
  color: #2DC2E5!important;
  border-color: #2DC2E5!important;
}

.outline-green{
  color: #00B480!important;
  border-color: #00B480!important;
}

.outline-red{
  color:#C12153!important;
  border-color:#C12153!important;
}

.text-primario{
  color: #16609B!important;
}

.text-secundario{
  color: #2DC2E5!important;
}

.text-white{
  color: #F2F9FD!important;
}

.bg-neutral{
  background-color: #F2F9FD;
}

.gradient-bg{
    background-color: #16609B!important;
    background-image: 
      radial-gradient(circle farthest-corner at top left, rgb(255, 255, 255) 0%, rgba(75, 64, 223, 0.183) 50%),
      radial-gradient(circle farthest-side at top right, rgb(38, 98, 187) 0%, rgba(44, 90, 228, 0.037) 39%),
      radial-gradient(circle farthest-corner at bottom right, rgb(20, 213, 247) 0%, rgba(204, 104, 119, 0) 33%),
      radial-gradient(circle farthest-corner at top right, rgba(155, 221, 240,1) 0%, rgba(155, 221, 240,0) 50%),
      radial-gradient(ellipse at bottom center, rgb(68, 204, 238) 0%, rgba(254, 43, 0, 0) 80%)!important;
}

/* & BOOTSTRAP COLOR VARIANTS */

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #16609B!important;
  --bs-btn-border-color: #16609B!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #16609B!important;
  --bs-btn-hover-border-color: #16609B!important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #16609B!important;
  --bs-btn-active-border-color: #16609B!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #16609B!important;
  --bs-btn-disabled-border-color: #16609B!important;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2DC2E5!important;
  --bs-btn-border-color: #2DC2E5!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2DC2E5!important;
  --bs-btn-hover-border-color: #2DC2E5!important;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2DC2E5!important;
  --bs-btn-active-border-color: #2DC2E5!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2DC2E5!important;
  --bs-btn-disabled-border-color: #2DC2E5!important;
}

.btn-green {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00B480!important;
  --bs-btn-border-color: #00B480!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00B480!important;
  --bs-btn-hover-border-color:  #00B480!important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00B480!important;
  --bs-btn-active-border-color:  #00B480!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00B480!important;
  --bs-btn-disabled-border-color: #00B480!important;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #C12153!important;
  --bs-btn-border-color: #C12153!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #C12153!important;
  --bs-btn-hover-border-color:  #C12153!important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #C12153!important;
  --bs-btn-active-border-color:  #C12153!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #C12153!important;
  --bs-btn-disabled-border-color: #C12153!important;
}

.btn-outline-secondary {
  --bs-btn-color: #2DC2E5!important;
  --bs-btn-border-color: #2DC2E5!important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2DC2E5!important;
  --bs-btn-hover-border-color: #2DC2E5!important;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2DC2E5!important;
  --bs-btn-active-border-color: #2DC2E5!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2DC2E5!important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2DC2E5!important;
  --bs-gradient: none;
}

.btn-outline-secondary:hover{
    color:  #fff!important;
    background-color: #2DC2E5!important;
    border-color: #2DC2E5!important;
}

/* SECTIONS */

.section-card{
  min-width: 25vw;
}

.section-icon-card{
  border-color:#2DC2E5!important ;
}

.activity-type-flag{
  position: absolute;
  z-index: 10;
  top: 0;
  left:0;
  width: 100px;
  padding: 5px;
}

.session-type-flag{
  position: absolute;
  z-index: 10;
  top: 0;
  left:0;
  max-width: 200px;
  padding: 5px;
}

/* BORDERS and DECORATIONS */

.no-border{
  border: none!important;
}

.no-radius-border{
  border-radius: 0%!important;
}

.no-decoration{
  text-decoration: none;
}

.cursor{
  cursor: pointer;
}

/* DROPDOWN MENU */
.dropdown-menu {
--bs-dropdown-link-active-bg : #f8f9fa!important;
--bs-dropdown-link-active-color: #212529!important;
}

/* TEXTS MODIFIERS */

.text-truncate{
  white-space: normal!important;
}

/*DAY PICKER SCHEDULE DATE*/



.day-picker-buttons{
 min-width: 200px;
}

.rdp-root{
  margin-left: 10%!important;
}
